/** @format */
.container-welcome {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh; /* Set the height of the container to the viewport height */
}

.welcome-msg {
	text-align: center;
	padding-top: 15%;
	font-size: 500%;
	font-weight: bold;
}

.welcome-msg-mobile {
	text-align: center;
	padding-top: 15%;
	font-size: 300%;
	font-weight: bold;
}
/* Navbar Styling */
.custom-navbar {
	border-bottom: 2px solid #e9ecef;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	font-family: "Roboto", sans-serif;
}

.custom-navbar .custom-link {
	color: #007bff;
	font-size: 1rem;
	font-weight: 600;
	margin-right: 15px;
	transition: color 0.3s ease;
}

.custom-navbar .custom-link:hover {
	color: #0056b3;
	text-decoration: none;
}

/* Background and Layout */
.bg-color {
	background-color: #f8f9fa;
	padding: 4rem 0;
}

.about-section {
	align-items: center;
}

/* Text Styling */
.text-section {
	color: #343a40;
	line-height: 1.8;
	font-family: "Roboto", sans-serif;
}

.section-title {
	font-size: 3rem;
	font-weight: bold;
	color: #222;
	margin-bottom: 1.5rem;
}

.section-description {
	font-size: 1.25rem;
	color: #6c757d;
	margin-bottom: 1rem;
}

/* Image Styling */
.bg-image {
	max-width: 100%;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Button Styling */
.custom-btn {
	border: 2px solid #007bff;
	color: #007bff;
	font-weight: bold;
	padding: 0.75rem 1.5rem;
	transition: all 0.3s ease;
}

.custom-btn:hover {
	background-color: #007bff;
	color: white;
}

body {
	margin: 0;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.logo-color {
	color: #3d84a8;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.header-center {
	text-align: center;
}

.welcome-msg {
	text-align: center;
	padding-top: 15%;
	font-size: 500%;
	font-weight: bold;
}

.flip-card {
	perspective: 1000px;
}

.flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 0.9s;
	transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	/* background-color: #3d84a8; Set background color here */
}

.flip-card-front {
	/* Additional front side styling */
}

.flip-card-back {
	/* Additional back side styling */
	transform: rotateY(180deg);
}

.flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
}

.bg-color {
	background-color: #f0f9fc;
}

ul {
	list-style-type: square;
}

.checked {
	color: orange;
}

/* General Container Styling */
.content-container {
	margin: 50px auto;
	padding: 50px 20px;
	background-color: #f9f9f9;
	border-radius: 10px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	max-width: 1200px;
}

/* Section Title Styling */
.section-title {
	font-size: 2.75rem;
	font-weight: bold;
	margin-bottom: 40px;
	text-align: center;
}

/* Features Row Styling */
.features-row {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	flex-wrap: wrap;
}

/* Features Column Styling */
.features-column {
	background: #ffffff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	flex: 1 1 45%; /* Responsive width: takes 45% of parent */
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.features-column:hover {
	transform: translateY(-5px);
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

/* Features List Styling */
.features-list {
	font-size: 1rem;
	list-style-type: none;
	padding-left: 0;
	color: #333333;
}

.features-list li {
	margin-bottom: 20px;
	line-height: 1.6;
}

/* Feature Heading */
.feature-heading {
	display: block;
	font-size: 1.1rem;
	font-weight: bold;
	color: #007bff;
	margin-bottom: 5px;
}

/* Feature Description */
.feature-description {
	font-size: 0.95rem;
	color: #555555;
}

/* Closing Text Styling */
.closing-text {
	margin-top: 40px;
	font-size: 1.25rem;
	text-align: center;
	font-weight: bold;
	color: #333333;
}

.closing-text .highlight {
	color: #007bff;
	font-style: italic;
}

/* Responsive Design */
@media (max-width: 768px) {
	.features-column {
		flex: 1 1 100%; /* Stacks columns on smaller screens */
	}
}
